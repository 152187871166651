import React from 'react';

class Title extends React.Component {
    constructor(props){
        super(props);
    }

    render() {

        if (this.props.type === 'h1') {
        return (
            <div className={'title '+ this.props.className}>
                <h1 id={ this.props.id } name={ this.props.name } style={{color:'#707070'}}>{ this.props.titleText }</h1>
            </div>
        )
        } else {
            return (
                <div className='subpage title'>
                    <h1 id={ this.props.id } name={ this.props.name } style={{color:'#707070'}}>{ this.props.titleText }</h1>
                </div>
            )
        }
    }
}
export default Title;
