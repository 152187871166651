import React from "react";
import { WebCemeteries } from "../../Components/WebCemeteries";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Title from "../../Components/Title";
import MediaQuery  from "react-responsive";
import axios from "axios";
import MobileNavOverlay from "../../Components/Navigation/MobileMenuOverlay";

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: {
                body: {
                    map_links: {
                        mobile: "",
                        desktop: ""
                    }
                }
                }
            }
        }

    async componentDidMount() {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/webcemeteries')
        .then(res => {
            const pageData = res.data;
            this.setState({ pageData })
        });
    }

    render() {

    return (
        <div className="subpage page-wrapper">
            <div className="header-region">
                <Header />
            </div>
            <hr className="page-top-border"></hr>
            <div className="body">
            <div className='subpage row desktop-page-top'>
                    <div className='column subpage page-content'>
                        <div className='subpage page-body history-page-body'>
                        <Title titleText="Cemetery Map" type="h1" />
                    </div>
                    </div>
                </div>
                <br />
                <br />
                <MediaQuery query='(min-width: 769px)'>
                <WebCemeteries url={ this.state.pageData.body.map_links.desktop }/>
                </MediaQuery>
                <MediaQuery query="(max-width: 768px)">
                <WebCemeteries url={ this.state.pageData.body.map_links.mobile }/>  
                    </MediaQuery>
                {/*
                <MediaQuery query="(max-width: 768px)">
                    <WebCemeteriesMobile />
                </MediaQuery> */}
                </div>
                <div id='mobile-menu' style={{display:'none'}}>
                    <MobileNavOverlay />
                </div>
        <div className="footer-region">
            <Footer />
            </div>
        </div>
    );
}
}
export default Map;