import React from 'react';
import axios from 'axios';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Title from '../../Components/Title';
import '../../StyleSheets/home.css';
import '../../StyleSheets/global.css';
import '../../StyleSheets/text.css'
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import MobileNavOverlay from '../../Components/Navigation/MobileMenuOverlay';
import PDF from '../../Assets/Documents/LC_application_20.pdf';
import MenuSquare from '../../Components/MenuSquare';
import '../../StyleSheets/menu-squares.css';
import WebCemeteriesRecentObituariesComponent from '../../Components/WebCemeteriesRecentObituariesComponent';


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: {
                    headers:"",
                    body: {
                        page_body:"",
                        page:"",
                        title:"",
                        banner_image:"",
                        body_image:"",
                        body_image_tablet:"",
                        body_image_mobile:"",
                        link_list: [],
                        nav_squares:[]
                    }
            }
        }
    }

    async fetchWebCemeteriesData() {
        const script = document.createElement('script');

        script.src = "https://apps.remembermyjourney.com/recent-obituaries/app.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        };
    }

    async componentDidMount() {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/home')
        .then(res => {
            const pageData = res.data;
            this.setState({ pageData })
            }
        );
        //await this.fetchWebCemeteriesData();
    }

    render() {

        if (this.state.pageData.body.page_body === "")
        {
            return "...";
        }
        else {
        const wrappedLinks = Object.keys(this.state.pageData.body.link_list)
        .map((key)=> {
        if ('List of Charges'===key) {
          return (<li className='menu-item'>
            <HashLink
              scroll={el => el.scrollIntoView({ behavior: 'instant', block: 'end' })}
              to={ this.state.pageData.body.link_list[key] }>{key}
          </HashLink>
        </li>)
        }

        else {
        return (<li className='menu-item'>
            <a
            href={ this.state.pageData.body.link_list[key] }>{key}
        </a>
        </li>)
        }
    });

    const wrappedSquares = Object.keys(this.state.pageData.body.nav_squares).map((key) => {

        return <div className='menu-square'>
            <MenuSquare 
            link={ this.state.pageData.body.nav_squares[key][0] }
            image={ this.state.pageData.body.nav_squares[key][1] }
            caption={ key }>
            </MenuSquare>
            </div>
            });
            return (
                <div className='home-page'>
                <div className='page-wrapper'>
                    <div className='header-region'>
                    <Header />
                    </div>
                    <div className='body home-body'>
                    <div class="banner-hero-outter">
                        <div class="banner-hero">
                            <h1 class="banner-heading">A place of peace<br />and prolific history</h1>
                        </div>
                    </div>
                        <div className='row menu-squares-section'>
                            { wrappedSquares }
                         </div> 
                    <div className='home row'>
                    <div className='home column page-content'>
                    {/*<Title titleText={ this.state.pageData.body.title } type='h1'/>*/}
                        <div className='home page-body'>
                            <br />
                        <h1>{this.state.pageData.body.page_body}</h1>
                       {/*} <div className='home body-image'>
                            <MediaQuery query='(max-width:414px)'>
                                <img src={ this.state.pageData.body.body_image_mobile } alt='414178_Longwood Cemetery_Davis_ Harold _Hank_ _Longwood Volunteer Photographer'/>
                                </MediaQuery>
                            <MediaQuery query='only screen and (min-width:415px) and (max-width:1199px)'>
                                <img src={ this.state.pageData.body.body_image_tablet } alt='414183_Longwood Cemetery_Davis_ Harold _Hank_ _Longwood Volunteer Photographer' />
                                </MediaQuery>
                            <MediaQuery query='(min-width:1200px)'>
                                <img src={ this.state.pageData.body.body_image } alt='414190_Longwood Cemetery_Davis_ Harold _Hank_ _Longwood Volunteer Photographer'/>
                                </MediaQuery>
            </div> */}
                                    <div className='web-cem-section'>
                                <WebCemeteriesRecentObituariesComponent />
                                </div>
                        </div>
                    </div>
                    
                    <div className='home column home-left-navigation'>
                    <p>
                        PLEASE NOTE: Please be sure to review <a href="/burials#lotcare">our lot care guidelines</a> in advance of placing tributes at a headstone.</p>
                    <br />

                    <div className="list-non-link">
                            <h3>Currently the Longwood Cemetery honors plots purchased prior to September 1, 2018. Sales are now closed.</h3>
                        </div>
                        <h1>Resources</h1> <br />
                        <ul>
                        <li className='menu-item'>
                              <a
                                href={ PDF }>Memorial and Foundation Application
                            </a>
                        </li>
                            { wrappedLinks }
                        </ul>
                    </div>
                </div>
               {/* <div id="webcem-recent-obits" data-key="8yP9DMYqpb3OWJmjnGPKJeQo0r1ALn" data-cemetery="6BmZ88ow" data-future-days="30" data-past-days="60" data-use-dod="false" data-primary="#FFFFFF" data-secondary="#e9e8e7" data-accent="#a59c94" data-text="#000000"></div>
                    <script async src="https://apps.remembermyjourney.com/recent-obituaries/app.js" charset="utf-8"></script> */}
                </div>
                <div id='mobile-menu' style={{ display:'none' }}>
                    <MobileNavOverlay />
                </div>
                <div className='footer-region'>
                <Footer />
                </div>
                </div>
                </div>
            );
        }
    }
}
export default Home;
