import React from 'react';
import axios from 'axios';
import '../../StyleSheets/text.css';
import '../../StyleSheets/nav.css';
import MobileMenuIcon from './MobileMenuIcon';
import MediaQuery from 'react-responsive';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import MobileNavOverlay from './MobileMenuOverlay';


class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navData: {
                headers:"",
                body: {
                    page:"",
                    link_list:[]
                }
            },
            menuState: 'closed'
        }
        this.toggleOverlayNav = this.toggleOverlayNav.bind(this);
    }

    async componentDidMount()
    {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/navigation')
        .then(res => {
            const navData = res.data;
            this.setState({ navData })
        });
    }

    toggleOverlayNav() {
        if (document.getElementById('slide-down-nav-overlay').style.height == '0%') {
            document.getElementById('slide-down-nav-overlay').style.height = '100%';
            document.getElementById('slide-down-nav-overlay').style.width = '100%';
        }
        else {
            document.getElementById('slide-down-nav-overlay').style.height = '0%';
            document.getElementById('slide-down-nav-overlay').style.width = '0%';
        }
    }

    render()
    {
        while (this.state.navData.headers == "")
        {
            return "...";
        }

        const wrappedLinks = Object.keys(this.state.navData.body.link_list).map((key) =>
            <li><Link className='top-nav link'to={this.state.navData.body.link_list[key]}>{key}</Link></li>);

        console.log(this);
            return (
                <div className="nav-wrapper">
                    <nav className="nav-large-screen">
                        <ul id="headerMenu">
                            <MediaQuery query='(min-width: 1025px)'>
                            { wrappedLinks }
                            </MediaQuery>
                            <MediaQuery query='(max-width: 1024px)'>
                                <div className='nav-icon' onClick={this.toggleOverlayNav}>
                                    <MobileMenuIcon />
                                    <MobileNavOverlay linkList={ wrappedLinks }/>
                                </div>
                            </MediaQuery>
                        </ul>
                    </nav>
                </div>
            );
        }
    }
export default Navigation;
