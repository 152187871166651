import React from 'react';
import '../../StyleSheets/nav.css';
import axios from 'axios';

class MobileMenuIcon extends React.Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            menuOpen:false
        }
        this.updateMenuState = this.updateMenuState.bind(this);
    }

    async componentDidMount()
    {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/navigation')
        .then(res => {
            const navData = res.data;
            this.setState({ navData })
        });
    }

    render() {
        return (
            <div onClick={ this.updateMenuState } className={ this.state.menuOpen ? 'change mobile-menu-icon' : 'mobile-menu-icon' }>
                <div className='bar1'></div>
                <div className='bar2'></div>
                <div className='bar3'></div>
            </div>
        );
    }

    updateMenuState = () => {
        this.setState(prevState => ({ menuOpen: !prevState.menuOpen }))
        if (document.getElementById('mobile-menu').style.display === 'none') {
            document.getElementsByClassName('body')[0].style.display = 'none';
            document.getElementById('mobile-menu').style.display = 'block';
            document.getElementsByClassName('lockup-region')[0].style.borderTop = 'none';
        }
        else {
            document.getElementsByClassName('body')[0].style.display = 'block';
            document.getElementById('mobile-menu').style.display = 'none';
            document.getElementsByClassName('lockup-region')[0].style.borderTop = '3px solid #A59C94';
        }
    }
}

export default MobileMenuIcon;
