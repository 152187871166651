import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Home from '../src/Pages/Home/Home-redesign';
import Burials from '../src/Pages/Burials/Burials';
import History from '../src/Pages/History/History';
import Faq from '../src/Pages/FAQ/Faq';
import Visit from '../src/Pages/Visit/Visit';
import CemeteryMap from '../src/Pages/Map/Map';
import Map from '../src/Pages/Visit/Map';
import TourMap from './Pages/Visit/TourMap';
import WebCemeteriesMapSearch from './Pages/Test/WebCemeteriesMapSearch';
import WebCemeteriesSingleTour from './Pages/Test/WebCemeteriesSingleTour';
import WebCemeteriesTourList from './Pages/Test/WebCemeteriesTourList';
import WebCemeteriesRecentObituaries from './Pages/Test/WebCemeteriesRecentObituaries';

ReactDOM.render((
    <Router>
    <div className='App'>
        <Route exact path="/" component={ Home } />
        <Route exact path="/burials" component={ Burials } />
        <Route exact path="/history" component={ History } />
        <Route exact path="/faq" component={ Faq } />
        <Route exact path="/visit" component={ Visit } />
        <Route exact path="/visit/map" component={ Map } />
        <Route exact path="/visit/tour" component={ WebCemeteriesTourList } />
        <Route exact path="/visit/mapsearch" component={ WebCemeteriesMapSearch } />
        <Route exact path="/visit/singletour" component={ WebCemeteriesSingleTour } />
        <Route exact path="/internal/test/webcemeteries/tourlist" component={ WebCemeteriesTourList } />
        <Route exact path="/internal/test/webcemeteries/recentobituaries" component={ WebCemeteriesRecentObituaries } />
        <Route path='/application' component={() => window.location = 'https://longwood-cemetery-cp.s3.amazonaws.com/LC_application_20.pdf'}/>
    </div>
</Router>), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
