import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Title from "../../Components/Title";
import { MediaQuery } from "react-responsive";
import axios from "axios";
import MobileNavOverlay from "../../Components/Navigation/MobileMenuOverlay";

class WebCemeteriesTourList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            pageData: {
                body: {
                }
            }
        }
    }

    async componentDidMount() {
        const script = document.createElement('script');

        script.src = "https://apps.remembermyjourney.com/tour-list/app.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        };
    }

    render() {
        return (
            <div className="subpage page-wrapper">
                <div className="header-region">
                    <Header />
                </div>
                <hr className='page-top-border'></hr>
                <div className='body'>
                <div id="webcem-tour-list" data-key="8yP9DMYqpb3OWJmjnGPKJeQo0r1ALn" data-map="AIzaSyBexKo2vkqIpYlWzyO_-x2OfhA8b2IZOq0" data-cemetery="6BmZ88ow" data-primary="#ffffff" data-secondary="#e9e8e7" data-accent="#a59c94" data-text="#000000"></div>
                <script src="https://apps.remembermyjourney.com/tour-list/app.js" charset="utf-8"></script>
                    <div id='mobile-menu' style={{display:'none'}}>
                    <MobileNavOverlay />
                </div>
                </div>
                <div id='mobile-menu' style={{display:'none'}}>
                    <MobileNavOverlay />
                </div>
            <div className="footer-region">
                <Footer />
                </div>
                </div>
        );
    }
}

export default WebCemeteriesTourList;