import React from 'react';
import Navigation from '../Navigation/Navigation';
import '../../StyleSheets/header.css';
import '../../fonts/icomoon/style.css'
import { Link } from 'react-router-dom';

class Header extends React.Component {
    render() {
        return (
            <div className="site-header">
                <div className="cemetery-lockup">
                    {/*}<Link to='/'>
                        <img id="headerLogo" src={ LogoUrl } alt="Longwood Cemetery, Est. 1855"/>
                    </Link>*/}
                    <Link to='/'>
                    <span className='icon-LC_logo_full icon-LC_Lockup'></span>
                    </Link>
                </div>
                <Navigation />
            </div>
        )
    }
}

export default Header;
