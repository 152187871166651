import React from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Title from '../../Components/Title';
import axios from 'axios';
import '../../StyleSheets/global.css';
import '../../StyleSheets/text.css';
import MobileNavOverlay from '../../Components/Navigation/MobileMenuOverlay';
import MapPDF from '../../Assets/Documents/Longwood_Cemetery_map.pdf';

class CemeteryMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: {
                headers:"",
                body: {
                    page:"",
                    title:"",
                    body_image:""
                }
            }
        }
    }

    async componentDidMount() {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/map')
        .then(res => {
            const pageData = res.data;
            this.setState({ pageData })
        });
    }

    render() {
        console.log(this.state.pageData);
        while (this.state.pageData.body.title == null) {
            return "...";
        }
        return (
            <div className='subpage page-wrapper'>
                <div className='header-region'>
                    <Header />
                </div>
                <div className='body'>
                <div className='subpage row desktop-page-top'>
                    <div className='subpage column page-content'>
                    <div className='subpage page-body'>
                        <Title titleText={ this.state.pageData.body.title } type='h1' />
                        <div className='subpage body-image map-image'>
                            <img src={ this.state.pageData.body.body_image } alt='map' />
                        </div>
                        <p id='mapLink'><a href={ MapPDF }>Download Map</a></p>
                        </div>
                    </div>
                </div>
                </div>
                <div id='mobile-menu' style={{display:'none'}}>
                    <MobileNavOverlay />
                </div>
                <div className='subpage footer-region'>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default CemeteryMap;
