import React from 'react';
import Title from './Title';


class DividedSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: this.props.list
        }
    }

    render() { 
        var wrappedListItem = [];
        while (this.props.list == null)
        {
            return "..."
        }
        if (this.props.titleText !== 'Cemetery Charges' && this.props.titleText !== '') {
            wrappedListItem = this.props.list.map((listItem) =>
                <li className='bulleted-item'>{ listItem }</li>);
            return (
                <div name={ this.props.name } className='section divided-section'>
                <Title titleText={ this.props.titleText } type={ this.props.type }/>
                <ul className='indented-list'>
                    <p>{ wrappedListItem }</p>
                </ul>
            </div>
            );
        }
        else if (this.props.titleText !== '') {
            wrappedListItem = this.props.list.map((listItem) =>
                <p className='item'>{ listItem }</p>);
            return (
                <div name={ this.props.name } id={ this.props.name } className='section divided-section'>
                    <Title titleText={ this.props.titleText } type={ this.props.type }/>
                    <ul className='unindented-list'>
                        <p>{ wrappedListItem }</p>
                    </ul>
                </div>
            );
        }
        else {
          wrappedListItem = this.props.list.map((listItem) =>
              <li className='bulleted-item'>{ listItem }</li>);
          return (
              <div name={ this.props.name } className='section divided-section-no-line'>
              <ul className='indented-list'>
                  <p>{ wrappedListItem }</p>
              </ul>
          </div>
          );
        }
    }
}

export default DividedSection;
