import React from "react";

export const WebCemeteries = ({ url }) => {
    return  (
        <iframe 
        title="webcemeteries" 
        src={ url } 
        scroll="auto" 
        frameBorder="0" 
        height="1000"
        allowtransparency="true" 
        style={{width: '100%'}}
    ></iframe>
    );
    /* <iframe 
        title="webcemeteries" 
        src="https://www.remembermyjourney.com/Search/577/Kiosk" 
        scroll="auto" 
        frameBorder="0" 
        height="1000"
        allowtransparency="true" 
        style={{width: '100%'}}
    ></iframe>
    );  */
}
