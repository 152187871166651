import React, { Fragment } from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import axios from 'axios';
import '../../StyleSheets/global.css';
import '../../StyleSheets/text.css';
import '../../StyleSheets/history.css';
import Title from '../../Components/Title';
import MediaQuery from 'react-responsive';
import MobileNavOverlay from '../../Components/Navigation/MobileMenuOverlay';

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: {
                headers:"",
                body: {
                    title:"",
                    page_body:[],
                    body_image:""
                }
            }
        }
    }

    async componentDidMount() {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/history')
        .then(res => {
            const pageData = res.data;
            this.setState({ pageData })
        });
    }

    render() {
        while (this.state.pageData.body.page_body == null)
        {
            return "..."
        }
        const wrappedParagraphs = this.state.pageData.body.page_body.map((paragraph) =>
            <p className='body-paragraph'>
                <Fragment>
                { paragraph }
                </Fragment><br /><br /></p>
        )

        const historyLink = <a href='https://whyy.org/episodes/the-underground-railroad-in-kennett-square/' target='_blank' rel='noopener noreferrer'>The cemetery was founded on the same anti-slavery and social reformist ideals by which the meeting house operated.</a>;
        
        return (
            <div className='subpage page-wrapper'>
                <div className='header-region'>
                <Header />
                </div>
                <hr className='page-top-border'></hr>
                <div className='body'>
                <MediaQuery query='(max-width:414px)'>
                <Title titleText={ this.state.pageData.body.title } />
                    <div className='subpage body-image history-body-image'>
                            <img className='history-body-image' src={ this.state.pageData.body.body_image } alt='414187_Longwood Cemetery_Davis_ Harold _Hank_ _Longwood Volunteer Photographer_.jpg' />
                        </div>
                        </MediaQuery>
                <div className='subpage row desktop-page-top'>
                    <div className='column subpage page-content'>
                        <div className='subpage page-body history-page-body'>
                        <MediaQuery query='(min-width:415px)'>
                        <Title titleText={ this.state.pageData.body.title } />
                    <div className='subpage body-image history-body-image'>
                            <img src={ this.state.pageData.body.body_image } alt='414187_Longwood Cemetery_Davis_ Harold _Hank_ _Longwood Volunteer Photographer_.jpg' />
                        </div>
                        </MediaQuery>
                    <div className='page-body history-page-text'>
                        { wrappedParagraphs }
                    </div>
                </div>
            </div>
                </div>
                </div>
                <div id='mobile-menu' style={{display:'none'}}>
                    <MobileNavOverlay />
                </div>
                <div className='footer-region'>
                <Footer />
                </div>
            </div>
        );
    }
}

export default History;