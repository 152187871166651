import React from 'react';
import '../StyleSheets/menu-squares.css';

class MenuSquare extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var str = this.props.caption.replace(' ', '-');
        str = str.toLowerCase();
        console.log(str);
		 const menuSquareCSS = `
		 	${'div#menu-square-' + str + ':before'} {
				background-image: url('${this.props.image}');
		 	}`;

        return <a className='menu-link' href={ this.props.link }>
			 <style>{menuSquareCSS}</style>
			 <div className='column menu-square ms-home' id= {'menu-square-' + str }>
                <h1 className='menu-caption'>{ this.props.caption }</h1>
            </div></a>
    }
}

export default MenuSquare;
