import React from 'react';

class Questions extends React.Component {
    constructor(props) {
        super(props);
    }   

    render() {
        var wrappedItems = [];
        while (this.props.questionList == null) {
            return "...";
        }
        wrappedItems = Object.keys(this.props.questionList).map((key) => 
            <div className='qa-pair'>
                <p className='question'>{key}</p>
                <p className='answer'>{ this.props.questionList[key] }</p>
            </div>);
        return(
            <div className='faq-items'>
                { wrappedItems }
            </div>
        );
    }
}

export default Questions;