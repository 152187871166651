import React from 'react';
import '../../StyleSheets/footer.css';
import '../../StyleSheets/global.css';
import '../../fonts/icomoon/style.css'

class Footer extends React.Component {
    render() {
        return (
            <div className='footer'>
                <div id='footer-row' className='row'>
                    <div className='column address-region'>
                        <p>945 East Baltimore Pike <br /> Kennett Square, PA
                        <br />
                        <br />
                        610.388.1235
                        </p>
                    </div>
                    <div className='column lockup-region'>
                        {/*}<p id='longwoodPropertyAnnotation'>A Longwood Gardens Property</p>*/}
                        <a href="https://longwoodgardens.org" style={{ textDecoration:'none' }}>
                          <span className='icon-LG-Logo-lockup_forweb icon-LG_Lockup'></span>
                        </a>
                        {/*}<a href="https://longwoodgardens.org" target='_blank'><img id='longwoodLockup' src={ logoLockup } alt='Longwood Gardens'></img></a>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
