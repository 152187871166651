import React from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Title from '../../Components/Title';
import axios from 'axios';
import '../../StyleSheets/global.css';
import '../../StyleSheets/text.css';
import MobileNavOverlay from '../../Components/Navigation/MobileMenuOverlay';
import DividedSection from '../../Components/DividedSection';
import MapPDF from '../../Assets/Documents/Longwood_Cemetery_map.pdf';
import  MediaQuery  from 'react-responsive';
import DropdownMenu from '../../Components/Navigation/DropdownMenu';
import { Media } from 'react-bootstrap';
import { WebCemeteries } from '../../Components/WebCemeteries';

class Visit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: {
                headers:"",
                body: {
                    page_body:
                        {
                            burial_search: {
                                desktop:"",
                                mobile:""
                            },
                            tour: {
                                desktop:"",
                                mobile:""
                            }
                        },
                    page:"",
                    visiting_guidelines:[],
                    title:"",
                    preservation_guidelines:[],
                    links: [],
                    link_map: []
                }
            },
            mapData: {
              headers:"",
              body: {
                page:"",
                title:"",
                body_image:""
              }
            }
        }
    }

    async componentDidMount() {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/visit')
        .then(res => {
            const pageData = res.data;
            this.setState({ pageData })
        })
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/map')
        .then(res => {
            const mapData = res.data;
            this.setState({ mapData })
        });
    }

    render() {
        while ( this.state.pageData.body.page_body == null) {
            return "...";
        }
        var wrappedVisitingItems = (this.state.pageData.body.visiting_guidelines).map((item) =>
            <li className='visiting-guideline-item'>{ item }</li>);

        var wrappedPreservationItems = (this.state.pageData.body.preservation_guidelines).map((item) =>
        <li className='visiting-guideline-item'>{ item }</li>);
        const backToTopLink =
          <a className='backToTopLink' href='#top' style={{textDecoration:'none'}}>Back to top</a>
        
        const newWrappedLinks = Object.keys(this.state.pageData.body.links).map((index) => 
        Object.keys(this.state.pageData.body.links[index]).map((title) => {
            if ('Cemetery Map & Burial Search'!==title && 'Tour' !== title) {
             return <a style={{textDecoration:'none'}} href={'#'+this.state.pageData.body.links[index][title]}><h3 className='menu-item'>{ title }</h3></a> 
            }
            else {
                return <a style={{textDecoration:'none'}} href={this.state.pageData.body.links[index][title]}><h3 className='menu-item'>{ title }</h3></a> 
            }
        }));

        return(
            <div className='subpage page-wrapper'>
                <div className='header-region'>
                    <Header />
                </div>
                <hr className='page-top-border'></hr>
                <div className='body'>
                <div id='top'></div>
                <MediaQuery query='(max-width: 1024px)'>
                <DropdownMenu title="Visit" linkMap={ this.state.pageData.body.link_map }/>
                    </MediaQuery>
                <div className='subpage row desktop-page-top'>
                    <div className='subpage column page-content'>
                        <div className='subpage page-body'>
                            <Title titleText={ this.state.pageData.body.title } type='h1'/>
                            <div className='section'>
                                <div id={ this.state.pageData.body.link_map["Visiting Guidelines"] }>
                                <p>{ this.state.pageData.body.page_body["subtitle"] }</p>
                                <DividedSection titleText='' name='' type='' list={ this.state.pageData.body.visiting_guidelines } />
                            </div>
                            <div className='section'>
                                <p>{ this.state.pageData.body.page_body["subtitle_2"] }</p>
                                <DividedSection titleText='' name='' type='' list={ this.state.pageData.body.preservation_guidelines } />
                            </div>
                            </div>
                            {/* <div className='section'>
                            { backToTopLink }
                                <div id={ this.state.pageData.body.link_map['Burial Search']}>
                                    <MediaQuery query='(min-width: 769px)'>
                                        <a href={ this.state.pageData.body.page_body.burial_search.desktop } target='_blank' rel='noopener noreferrer'><h1 className='subpage title'>Burial Search</h1></a>
                                    </MediaQuery>
                                    <MediaQuery query='(max-width: 768px)'>
                                    <a href={ this.state.pageData.body.page_body.burial_search.mobile } target='_blank' rel='noopener noreferrer'><h1 className='subpage title'>Burial Search</h1></a>
                                        </MediaQuery>
                                </div>
                        </div>
                        <div className='section'>
                                <div id={ this.state.pageData.body.link_map['Tour']}>
                                { backToTopLink }
                                <MediaQuery query='(min-width: 769px)'>
                                        <a href={ this.state.pageData.body.page_body.tour.desktop } target='_blank' rel='noopener noreferrer'><h1 className='subpage title'>Tour</h1></a>
                                    </MediaQuery>
                                    <MediaQuery query='(max-width: 768px)'>
                                    <a href={ this.state.pageData.body.page_body.tour.mobile } target='_blank' rel='noopener noreferrer'><h1 className='subpage title'>Tour</h1></a>
                                        </MediaQuery>
                                </div>
                        </div> */}
                        <div className='section'>
                                <div id={ this.state.pageData.body.link_map['Plot Care Guidelines & Grave Tributes']}>
                                    { backToTopLink }
                                <DividedSection titleText='Plot Care Guidelines & Grave Tributes' type='h2' list={ this.state.pageData.body.page_body['plot_care'] } />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className='subpage column page-content'>
                    {/*<div id='map' name='map' className='subpage page-body'>
                    { backToTopLink }
                        <Title titleText={ this.state.mapData.body.title } type='h1' />
                        <p id='mapLink'><a href={ MapPDF }>Download Map</a></p>
                        <div className='subpage body-image map-image'>
                            <img src={ this.state.mapData.body.body_image } alt='map' />
                        </div>
                        <WebCemeteries />
                        </div>*/}
                    </div>
                    <MediaQuery query='(min-width:769px)'>
                    <div className='column subpage-nav left-navigation'>
                      {/*  <h1 className='nav-title'>{ this.state.pageData.body.title }</h1> */}
                        <ul className='left-nav'>
                            { newWrappedLinks }
                        </ul>
                    </div>
                    </MediaQuery>
                </div>
                </div>
                <div id='mobile-menu' style={{display:'none'}}>
                    <MobileNavOverlay />
                </div>
                <div className='subpage footer-region' id='visit-footer'>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Visit;
