import React from 'react';
import '../../StyleSheets/nav.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { HashLink as HashLink } from 'react-router-hash-link';

class MobileNavOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navData: {
                headers:"",
                body: {
                    page:"",
                    link_list:[]
                }
            }
        }
    }

    async componentDidMount()
    {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/navigation')
        .then(res => {
            const navData = res.data;
            this.setState({ navData })
        });
    }

    render() {
        while (this.state.navData.body.page == null) {
            return "...";
        }
        const wrappedLinks = Object.keys(this.state.navData.body.link_list).map((key) =>
        {
          if ('Map'===key) {
            return <li><p><HashLink to={ this.state.navData.body.link_list[key] }>[key]</HashLink></p></li>
          } else {
            return <li><p><Link className='mobile-nav-link' to={this.state.navData.body.link_list[key]}>{key}</Link></p></li>
          }
        }
      );
        return(
            <div id='slide-down-nav-overlay'>
                    <div className='overlay-content'>
                        <ul className='mobile-nav-links'>
                            { wrappedLinks }
                        </ul>

                </div>
            </div>
        );
    }
}

export default MobileNavOverlay;
