import React from 'react';
import '../../StyleSheets/nav.css'
class DropdownArrow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
        this.updateMenuState = this.updateMenuState.bind(this);
    }

    render() {
        return(
            <div className="show-hide" onClick={ this.updateMenuState }>
                <div className="left"></div>
                <div className="right"></div>
            </div>
        );
    }

    updateMenuState = () => {
       this.setState(prevState => ({ menuOpen: !prevState.menuOpen }))
       var arrows = document.getElementsByClassName('show-hide')[0].children;
       var arrList = Array.from(arrows);
        if (!this.state.menuOpen) {
            arrList.forEach(element => element.classList.add('open'));
            document.getElementsByClassName('dropdown-list')[0].classList.add('show');
        }
        else {
            arrList.forEach(element => element.classList.remove('open'));
            document.getElementsByClassName('dropdown-list')[0].classList.remove('show');
            
        }
    }
}

export default DropdownArrow;