import React from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Title from '../../Components/Title';
import axios from 'axios';
import '../../StyleSheets/global.css';
import '../../StyleSheets/text.css';
import '../../StyleSheets/burials.css';
import DividedSection from '../../Components/DividedSection';
import MediaQuery from 'react-responsive';
import MobileNavOverlay from '../../Components/Navigation/MobileMenuOverlay';
import DropdownMenu from '../../Components/Navigation/DropdownMenu';
import PDF  from '../../Assets/Documents/LC_application_20.pdf';

class Burials extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
            pageData: {
                headers:"",
                body: {
                    page_body:[],
                    page:"",
                    title:"",
                    banner_image:"",
                    link_list:[],
                    subtitle:[],
                    body_image:"",
                    link_map:[],
                    links:[]
                }
            }
        }
    }

    async componentDidMount() {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/burials')
        .then(res => {
            const pageData = res.data;
            this.setState({ pageData })
        })
    }

    render()
    {
        if (this.state.pageData.body === null) {
            return "...";
        }
        else {
        var styles = {
            position:'absolute',
            top: '0px',
            left: '0px',
            transform: 'translate3d(0px, 37px, 0px)',
            willChange: 'transform'
        };
        const backToTopLink =
          <a className='backToTopLink' href='#top' style={{textDecoration:'none'}}>Back to top</a>

        const newWrappedLinks = Object.keys(this.state.pageData.body.links).map((index) => Object.keys(this.state.pageData.body.links[index]).map((title) =>  <a style={{textDecoration:'none'}} href={'#'+this.state.pageData.body.links[index][title]}><h3 className='menu-item'>{ title }</h3></a> ));

        const wrappedNavLinks = Object.keys(this.state.pageData.body.link_map).map((title) =>
        <a style={{textDecoration:'none'}} href={'#'+this.state.pageData.body.link_map[title]}><h3 className='menu-item'>{ title }</h3></a>
        );

        return (
            <div className='subpage page-wrapper'>
                <div className='header-region'>
                <Header />
                </div>
                <hr className='page-top-border-burials'></hr>
                <div className='body'>
                  <div id='top'></div>
                <MediaQuery query='(max-width: 1024px)'>
                <DropdownMenu title="Burials" linkMap={ this.state.pageData.body.link_map }/>
                    </MediaQuery>
                <MediaQuery query='(max-width:414px)'>
                <Title className='subpage-page-title' titleText={ this.state.pageData.body.title } />
                <div className='subpage body-image' >
                    <img src={ this.state.pageData.body.body_image } alt='burials.jpg' />
                </div>
                </MediaQuery>
                <div className='subpage row desktop-page-top'>
                    <div className='subpage column page-content'>
                        <div className='subpage page-body'>
                            <MediaQuery query='(min-width:415px)'>
                                <Title className='subpage-page-title' titleText={ this.state.pageData.body.title } type='h1' />
                                <div className='subpage body-image' >
                                    <img src={ this.state.pageData.body.body_image } alt='burials.jpg' />
                                </div>
                             </MediaQuery>
                             <div className='section divided-section'>
                                <Title titleText='Guidelines' type='h1' />
                                <p className='guidelines-body'>{ this.state.pageData.body.subtitle['Guidelines'] }</p>
                             </div>
                             <div id={ this.state.pageData.body.link_map['Funerals and Burials'] }>
                                { backToTopLink }
                                <DividedSection titleText='Funerals and Burials' type='h2' list={ this.state.pageData.body.page_body['Funerals and Burials'] } />
                                </div>
                            <div id={ this.state.pageData.body.link_map['Monuments'] }>
                                { backToTopLink }
                                <DividedSection titleText='Monuments' type='h2' list={ this.state.pageData.body.page_body['Monuments'] } />
                            </div>
                            <div id={ this.state.pageData.body.link_map['Foundations'] }>
                              { backToTopLink }
                            <DividedSection titleText='Foundations' type='h2' list={ this.state.pageData.body.page_body['Foundations' ]} />
                            </div>

                            <div id={ this.state.pageData.body.link_map['Cemetery Charges']}>
                              { backToTopLink }
                                <DividedSection name='charges' titleText='Cemetery Charges' type='h2' list={ this.state.pageData.body.page_body['Cemetery Charges'] } />
                            </div>
                            <div id='application'>
                            { backToTopLink }
                            <Title titleText='Memorial and Foundation Application' type='h2' />
                            <ul className='unindented-list'>
                                <p><a href={ PDF }>Download Application</a></p>
                            </ul>
                            </div>
                        </div>
                    </div>
                    <MediaQuery query='(min-width:769px)'>
                    <div className='column subpage-nav left-navigation'>
                      {/*  <h1 className='nav-title'>{ this.state.pageData.body.title }</h1> */}
                        <ul className='left-nav'>
                            { newWrappedLinks }
                        </ul>
                    </div>
                    </MediaQuery>
                </div>
                </div>
                <div id='mobile-menu' style={{ display:'none' }}>
                    <MobileNavOverlay />
                </div>
                <div className='subpage footer-region'>
                <Footer />
                </div>
            </div>
        )
        }
    }
}
export default Burials;