import React from 'react';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Title from '../../Components/Title';
import Questions from '../../Components/Questions';
import MobileNavOverlay from '../../Components/Navigation/MobileMenuOverlay';
import axios from 'axios';
import '../../StyleSheets/global.css';
import '../../StyleSheets/text.css';
import MediaQuery from 'react-responsive';

class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageData: {
                headers:"",
                body: {
                    page_body: {
                        questions:[]
                    },
                    title:"",
                    page:""
                }
            }
        }
    }

    async componentDidMount() {
        axios.get('https://bsvfo8m0wi.execute-api.us-east-1.amazonaws.com/longwood-cemetery/GetCemeterySiteData/faq')
        .then(res => {
            const pageData = res.data;
            this.setState({ pageData })
        })
    }

    render() {
        while (this.state.pageData.body.page_body == null) 
        {
            return ".."
        }
        return(
            <div className='subpage page-wrapper'>
            <div className='header-region faq-header-region'>
                <Header />
            </div>
            <div className='body faq-body'>
                <div className='subpage row desktop-page-top'>
                    <div className='subpage column page-content'>
                        <div className='subpage page-body'>
                            <Title className='subpage-page-title' titleText={ this.state.pageData.body.title } type='h1'/>
                            <div className='section'>
                                <Questions questionList={ this.state.pageData.body.page_body.questions } />
                            </div>
                        </div>
                    </div>
                    <MediaQuery query='(min-width:1025px)'>
                    <div className='column subpage-nav left-navigation'>
                        
                    </div>
                    </MediaQuery>
                </div>
                </div>
                <div id='mobile-menu' style={{display:'none'}}>
                    <MobileNavOverlay />
                </div>
                <div className='subpage footer-region' id='faq-footer'>
                    <Footer />
                </div>
            </div>
        );
    }
}

export default Faq;

