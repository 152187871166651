import React from 'react';
import DropdownArrow from '../../Components/Navigation/DropdownArrow';
import '../../StyleSheets/nav.css';

class DropdownMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const wrappedNavLinks = Object.keys(this.props.linkMap).map((title) => {
        if ('Cemetery Map & Burial Search'!==title && 'Tour' !== title) {
            return <div className='dropdown-menu-item'>
            <a style={{textDecoration:'none'}} href={'#'+this.props.linkMap[title]}><p className='menu-item-text'>{ title }</p></a>
            </div>
        } else {
            return <div className='dropdown-menu-item'>
            <a style={{textDecoration:'none'}} href={   this.props.linkMap[title]}><p className='menu-item-text'>{ title }</p></a>
            </div>
        }
        });
        return(
            <div className='custom-dropdown-menu'>
                <div className='menu row' id='menuRow'>
                <div className='title-region column'><h3 className='menu-title'>{ this.props.title }</h3></div>
                <div className='arrow-region column'>
                <DropdownArrow />
                </div>
                </div>
                <div className='dropdown-list'>
                    <ul className='dropdown-ul'>
                        { wrappedNavLinks }
                    </ul>
                </div>
            </div>
        );
    }
}

export default DropdownMenu;
